class Config {
  constructor () {
    this.graphBackend = 'https://www.test-graph.ntu.asia/'
    this.basicBackendBaseURL = 'https://www.test-backend.ntu.asia/'
    this.recordGetMaxAmountBasedOnBackendSet = 10
    // 動態制或栽培曆相關設定
    this.minSelectedDate = '2021-01-01'
    this.calendarBackendURL = 'https://www.text-cal-vbf1.ntu.asia/'
  }
}

export default new Config()
